import { Component } from '@angular/core';
import {NavigationService} from '../../../services/navigation.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent  {

  constructor(public navigationService : NavigationService ) { 
    this.navigationService.setShowNav(false);
    this.navigationService.setMobileNav(false);
    this.navigationService.setPageTitle('Stolt Tankers About');
  }

}
