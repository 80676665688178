import { ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FrontModule } from './front/front.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SideNavComponent } from './front/reusable_components/side-nav/side-nav.component';
import { SideNavContentComponent } from './front/reusable_components/side-nav-content/side-nav-content.component';

import { FourZeroFourComponent } from './front/pages/four-zero-four/four-zero-four.component';
import { AngularSvgIconPreloaderModule } from 'angular-svg-icon-preloader';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { MsalGuard, MsalBroadcastService, MsalModule, MsalService, MsalRedirectComponent, MsalInterceptor } from '@azure/msal-angular';
import { msalConfig } from './front/auth/auth-config';

import { NgScrollbarModule } from 'ngx-scrollbar';
import { environment } from '../environments/environment';
import { ToastrModule } from 'ngx-toastr';
import { ApplicationInsightsErrorHandlerService } from './services/application-insights-error-handler.service'
import { NgxCaptchaModule } from 'ngx-captcha';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { SnlComponentsModule } from 'snl-complib';
const routerextraOptions : ExtraOptions={
  scrollPositionRestoration:'enabled',
  anchorScrolling:'enabled'
}
const routes: Routes = []
@NgModule({
  declarations: [
    AppComponent,
    SideNavComponent,
    SideNavContentComponent,
    FourZeroFourComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FrontModule,
    AppRoutingModule,
    NgScrollbarModule,
    BrowserAnimationsModule,
    NgxCaptchaModule,
    SnlComponentsModule,
    AngularSvgIconModule.forRoot(), // angular-svg-icon library module
    AngularSvgIconPreloaderModule.forRoot({
      configUrl: './assets/json/icons.json',
    }),
    ToastrModule.forRoot(),
    RouterModule.forRoot(routes,routerextraOptions),

    MsalModule.forRoot(new PublicClientApplication(msalConfig),
      {
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: ['https://graph.microsoft.com/User.Read', "https://graph.microsoft.com/profile"]
        },
        loginFailedRoute: '/'
      }, {
      interactionType: InteractionType.Redirect,
      protectedResourceMap: new Map<string, Array<string> | null>([
        ["https://graph.microsoft.com/v1.0/me", ["https://graph.microsoft.com/User.Read", "https://graph.microsoft.com/profile"]],
        [environment.API_BASE_URL + environment.AUTH_SERVICE_BASE_NAME + `/api/getUserInfo`, [environment.USERSCOPE]],
        [environment.API_BASE_URL + environment.BOOKING_SERVICE_BASE_NAME + `/api/fetchBookingListing`, [environment.USERSCOPE]],
        [environment.API_BASE_URL + environment.AUTH_SERVICE_BASE_NAME + `/api/viewDistributionList`, [environment.USERSCOPE]],
        [environment.API_BASE_URL + environment.BOOKING_SERVICE_BASE_NAME + `/api/fetchBookingFilters`, [environment.USERSCOPE]],
        [environment.API_BASE_URL + environment.BOOKING_SERVICE_BASE_NAME + `/api/postFilters`, [environment.USERSCOPE]],
        [environment.API_BASE_URL + environment.BOOKING_SERVICE_BASE_NAME + `/api/getSavedFilters`, [environment.USERSCOPE]],
        [environment.API_BASE_URL + environment.BOOKING_SERVICE_BASE_NAME + `/api/fetchBookingDetail`, [environment.USERSCOPE]],
        [environment.API_BASE_URL + environment.SUSTAINABILITY_SERVICE_BASE_NAME + `/api/getSustainabilityForBooking`, [environment.USERSCOPE]],
        [environment.API_BASE_URL + environment.BOOKING_SERVICE_BASE_NAME + `/api/sendMail`, [environment.USERSCOPE]],
        [environment.API_BASE_URL + environment.BOOKING_SERVICE_BASE_NAME + `/api/documentationZipReq`, [environment.USERSCOPE]],
        [environment.API_BASE_URL + environment.BOOKING_SERVICE_BASE_NAME + `/api/downloadZipDocumentation`, [environment.USERSCOPE]],
        [environment.API_BASE_URL + environment.BOOKING_SERVICE_BASE_NAME + `/api/downloadBookingsPath`, [environment.USERSCOPE]],

        [environment.API_BASE_URL + environment.BOOKING_SERVICE_BASE_NAME + `/api/sendBDetailDocMail`, [environment.USERSCOPE]],
        [environment.API_BASE_URL + environment.BOOKING_SERVICE_BASE_NAME + `/api/downloadBookings`, [environment.USERSCOPE]],
        [environment.API_BASE_URL + environment.SUSTAINABILITY_SERVICE_BASE_NAME + `/api/getSustainabilityGraphs`, [environment.USERSCOPE]],
        [environment.API_BASE_URL + environment.SUSTAINABILITY_SERVICE_BASE_NAME + `/api/generateSCCReport`, [environment.USERSCOPE]],
        [environment.API_BASE_URL + environment.BOOKING_SERVICE_BASE_NAME + `/api/addBookingVariance`, [environment.USERSCOPE]],
        [environment.API_BASE_URL + environment.AUTH_SERVICE_BASE_NAME + `/api/saveNotifications`, [environment.USERSCOPE]],
        [environment.API_BASE_URL + environment.AUTH_SERVICE_BASE_NAME + `/api/getNotifications`, [environment.USERSCOPE]],

        [environment.API_BASE_URL + environment.AUTH_SERVICE_BASE_NAME + `/api/getSurveyQues`, [environment.USERSCOPE]],
        [environment.API_BASE_URL + environment.AUTH_SERVICE_BASE_NAME + `/api/postSurveyExpiryDate`, [environment.USERSCOPE]],
        [environment.API_BASE_URL + environment.AUTH_SERVICE_BASE_NAME + `/api/postFeedback`, [environment.USERSCOPE]],
        [environment.API_BASE_URL + environment.AUTH_SERVICE_BASE_NAME + `/api/trackUser`, [environment.USERSCOPE]],

        [environment.API_BASE_URL + environment.SUSTAINABILITY_SERVICE_BASE_NAME + `/api/fetchSusChartDashboardData`, [environment.USERSCOPE]],
        [environment.API_BASE_URL + environment.SUSTAINABILITY_SERVICE_BASE_NAME + `/api/fetchSustanabilityFiltersOptions`, [environment.USERSCOPE]],

        [environment.API_BASE_URL + environment.SUSTAINABILITY_SERVICE_BASE_NAME + `/api/fetchSusBookingDashboardData`, [environment.USERSCOPE]],
        [environment.API_BASE_URL + environment.SUSTAINABILITY_SERVICE_BASE_NAME + `/api/filterSusChartData`, [environment.USERSCOPE]],
        [environment.API_BASE_URL + environment.SUSTAINABILITY_SERVICE_BASE_NAME + `/api/createSustainBookingsXLRangeWise`, [environment.USERSCOPE]],
        [environment.API_BASE_URL + environment.SUSTAINABILITY_SERVICE_BASE_NAME + `/api/downloadSustainBookingsXLRangeWise`, [environment.USERSCOPE]],
        [environment.API_BASE_URL + environment.SUSTAINABILITY_SERVICE_BASE_NAME + `/api/updateSustainabilityGraphFilter`, [environment.USERSCOPE]],
        [environment.API_BASE_URL + environment.SUSTAINABILITY_SERVICE_BASE_NAME + `/api/shareSustainabilityBookingByMail`, [environment.USERSCOPE]],

        [environment.API_BASE_URL + environment.AUTH_SERVICE_BASE_NAME + `/api/addDistributionList`, [environment.USERSCOPE]],
        [environment.API_BASE_URL + environment.AUTH_SERVICE_BASE_NAME + `/api/readBookingsByUser`, [environment.USERSCOPE]],
        [environment.API_BASE_URL + environment.BOOKING_SERVICE_BASE_NAME + `/api/createBookingsXLRangeWise`, [environment.USERSCOPE]],
        [environment.API_BASE_URL + environment.BOOKING_SERVICE_BASE_NAME + `/api/downloadBookingsXLRangeWise`, [environment.USERSCOPE]],

        [environment.API_BASE_URL + environment.AUTH_SERVICE_BASE_NAME + `/api/getUserSearchHistory`, [environment.USERSCOPE]],
        [environment.API_BASE_URL + environment.AUTH_SERVICE_BASE_NAME + `/api/postUserSearchHistory`, [environment.USERSCOPE]],
        [environment.API_BASE_URL + environment.BOOKING_SERVICE_BASE_NAME + `/api/getGlobalSearch`, [environment.USERSCOPE]],

        [environment.API_BASE_URL + environment.BOOKING_SERVICE_BASE_NAME + `/api/getDocumentationfilter`, [environment.USERSCOPE]],
        [environment.API_BASE_URL + environment.BOOKING_SERVICE_BASE_NAME + `/api/getDocumentationBookingList`, [environment.USERSCOPE]],
        [environment.API_BASE_URL + environment.BOOKING_SERVICE_BASE_NAME + `/api/getDocumentationSavedFilter`, [environment.USERSCOPE]],
        [environment.API_BASE_URL + environment.BOOKING_SERVICE_BASE_NAME + `/api/fetchUserSettingsFilters`, [environment.USERSCOPE]],
        [environment.API_BASE_URL + environment.BOOKING_SERVICE_BASE_NAME + `/api/getBookingDetailDocumentation`, [environment.USERSCOPE]],
        [environment.API_BASE_URL + environment.BOOKING_SERVICE_BASE_NAME + `/api/getBookingGlobalSearchListing`, [environment.USERSCOPE]],
        [environment.API_BASE_URL + environment.AUTH_SERVICE_BASE_NAME + `/api/saveNotificationsSettings`, [environment.USERSCOPE]],
        [environment.API_BASE_URL + environment.AUTH_SERVICE_BASE_NAME + `/api/getNotificationsSettings`, [environment.USERSCOPE]],
        [environment.API_BASE_URL + environment.AUTH_SERVICE_BASE_NAME + `/api/deleteNotificationsSettings`, [environment.USERSCOPE]],     
        [environment.API_BASE_URL + environment.AUTH_SERVICE_BASE_NAME + `/api/getUsersList`, [environment.USERSCOPE]],
        [environment.API_BASE_URL + environment.AUTH_SERVICE_BASE_NAME + `/api/userListFilters`, [environment.USERSCOPE]],
        [environment.API_BASE_URL + environment.AUTH_SERVICE_BASE_NAME + `/api/updateUsers`, [environment.USERSCOPE]],
        [environment.API_BASE_URL + environment.AUTH_SERVICE_BASE_NAME + `/api/deleteUsers`, [environment.USERSCOPE]],
        [environment.API_BASE_URL + environment.AUTH_SERVICE_BASE_NAME + `/api/charterers`, [environment.USERSCOPE]],
        [environment.API_BASE_URL + environment.AUTH_SERVICE_BASE_NAME + `/api/getBrokers`, [environment.USERSCOPE]],
        [environment.API_BASE_URL + environment.AUTH_SERVICE_BASE_NAME + `/api/addUsers`, [environment.USERSCOPE]],
        [environment.API_BASE_URL + environment.BOOKING_SERVICE_BASE_NAME + `/api/getCountUnreadBookingDoc`, [environment.USERSCOPE]],       
        [environment.API_BASE_URL + environment.BOOKING_SERVICE_BASE_NAME + `/api/getCargoDetailDocumentation`, [environment.USERSCOPE]],
        [environment.API_BASE_URL + environment.AUTH_SERVICE_BASE_NAME + `/api/getBookingDetailNotifications`, [environment.USERSCOPE]],        
        [environment.API_BASE_URL + environment.SUSTAINABILITY_SERVICE_BASE_NAME + `/api/postFilterSustainability`, [environment.USERSCOPE]]     
        
      ]),
    })
  ],
  exports: [SnlComponentsModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    {
      provide: ErrorHandler,
      useClass: ApplicationInsightsErrorHandlerService
    }
  ],
  bootstrap: [AppComponent, MsalRedirectComponent
  ]
})
export class AppModule { }
