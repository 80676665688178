import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from '../../../services/navigation.service';
import { PublicPagesService } from '../../../services/public-pages.service';
import { TrackVessel, TrackPort } from '../../../models/track';
import { LaycanObject } from '../../../models/booking';
import { Observable } from 'rxjs';
import { getLatLong } from "../../../helpers/degreeLatLong";

@Component({
  selector: 'app-track',
  templateUrl: './track.component.html',
  styleUrls: ['./track.component.scss']
})
export class TrackComponent implements OnInit {
  @ViewChild('filterClass') filterClass: ElementRef;
  @ViewChild('filterTooltipModelClass') filterTooltipModelClass: ElementRef;
  vs_Name = '';
  clickedVessalLocation: any[] = [];
  trackPageData: {
    'bookingStatus': string,
    'dtBookingLastUpdatedOn': string,
    'dtCreatedOn': string,
    'dtLastUpdatedOn': string,
    'fleet': string,
    'idBooking': number,
    'overallDelay': number,
    'laycanObjectArrTrack': LaycanObject[],
    'isLaycan': boolean,
  };
  minDelay = 1;
  maxDelay = 3;
  arVesselTrack: TrackVessel[] = [];
  delayObj: any[] = [];
  isLogedin: Observable<boolean>;
  bookingVariance = 'All';
  vesselData: any;
  count: number;
  modelType: any;
  overFlowClass: boolean;
  bookingDetail: { id: string; label: string; }[];

  constructor(public navigationService: NavigationService, private publicPagesService: PublicPagesService, private route: ActivatedRoute, public renderer : Renderer2) {
    this.navigationService.setShowNav(false);
    this.navigationService.setMobileNav(false);
    this.navigationService.setPageTitle('Stolt Tankers-Track');
    this.isLogedin = this.navigationService.isLoginGet();
  }

  mapCurrentPort = (value: TrackPort[]) => {
    let indexValue = value.map(it => it.isPortCompleted).lastIndexOf(true);
    return value.map((it: TrackPort, index: number) => {
      if (index == indexValue) {
        return { ...it, 
          isCurrentPort: true, 
          show: 3, 
          isEta: false, 
          portName: it.portName ? it.portName.replace(/ *\([^)]*\) */g, "") : '', 
          etaDelay: this.getetaDelay(it) }
      }
      else {
        return { ...it, 
          isDepartured: false, 
          isCurrentPort: false, 
          show: 3, 
          isEta: false, 
          portName: it.portName ? it.portName.replace(/ *\([^)]*\) */g, "") : '', 
          etaDelay: this.getetaDelay(it) }
      }
    })
  }

  getetaDelay(it:any){
    if(it.delayReason && it.delayReason.length > 0){
      if(this.bookingVariance == 'All'){
        return it.delayReason[0].ETADelayInDays;
      } else {
        return it.delayReason[0].delayDaysforLast24HrsETA;
      }
    } else {
      return 0
    }
  }

  ngOnInit(): void {
    this.route.params.subscribe(paramsId => {
      const randomeNo = paramsId.id;
      this.publicPagesService.trackId(randomeNo).subscribe((res: any) => {
        if (res.data) {
          this.trackPageData = {
            'bookingStatus': res.data.bookingStatus,
            'dtBookingLastUpdatedOn': res.data.dtBookingLastUpdatedOn,
            'dtCreatedOn': res.data.dtCreatedOn,
            'dtLastUpdatedOn': res.data.dtLastUpdatedOn,
            'fleet': res.data.fleet,
            'idBooking': res.data.idBooking,
            'overallDelay': res.data.overallDelay,
            'laycanObjectArrTrack': res.data.laycanObjectArr,
            'isLaycan': false,
          };
          this.setDelayData();
          if (res.data.arVessel) {
            this.refactorVesselData(res)
          }
        }
      });
    });
    
  }

  refactorVesselData(res:any){
    this.evaluateVessalData(res.data.arVessel).then((ress: any) => {
      this.vesselData = ress
      this.arVesselTrack = ress ? ress.map((it:any)=> it && it.arPorts ? {...it, RepeatVoyege: this.getRepeatedVoyege(it.arPorts)} : ({...it})) : [];

      this.arVesselTrack.forEach((element:any) => {
        this.arCargoesLoadDisValue(element);
      });

      let detaildata = this.arVesselTrack[0].vesselName ?(this.arVesselTrack[0].vesselName): ''
    let UpbookingDetail = detaildata ? detaildata[0].toUpperCase() + detaildata.slice(1).toLowerCase() : '';
    this.bookingDetail = [ { id: '1', label: 'My Bookings' },{ id: '3', label:this.trackPageData?.idBooking+ '-' + UpbookingDetail}]
    });
  }

    // Vessel status method
    arCargoesLoadDisValue(vessel: any){
      let value = vessel.arPorts;
      let currentPortNumber =  vessel.currentPortNumber;
      let currentPortIndex = value.findIndex((t: any) => (currentPortNumber == t.portNumber));
      if(currentPortIndex < 0){
        currentPortIndex = 0;
      }
      if(value.length){
        this.refactorLoop(currentPortIndex,value,vessel)
      }
    }

    refactorLoop(currentPortIndex:any,value:any,vessel:any){
      this.count = 0;
      for (let index = currentPortIndex; index < value.length; index++) {
        const element = value[index];
          if(element.isPortCompleted && element.isDepartured){
          element['isComLoad'] = true; // isComLoad = combination of isLoad in arCargoes array
          element['isComDischarge'] = true; // isComDischarge = combination of isDischarge in arCargoes array
          vessel['filterPort'] = element; // filterPort = for vessel status 
        }else {
          this.refactorArCargoes(element,vessel)
        }
      }
    }

    refactorArCargoes(element:any,vessel:any){
      let isComLoad: boolean = true;
      let isComDischarge : boolean = true;
      if(element.arCargoes.length > 0){
        this.count ++;
        element.arCargoes.forEach((element: any) => {
          if(!isComLoad){
              isComLoad = element.isLoad;
          }else {
            isComLoad = isComLoad && element.isLoad;
          }
          if(!isComDischarge){
            isComDischarge = element.isDischarge;
        }else {
          isComDischarge = isComDischarge && element.isDischarge;
        }
        });
        element['isComLoad'] = isComLoad;
        element['isComDischarge'] = isComDischarge;
        this.refactorFilterPort(vessel,element)
      }
    }

    refactorFilterPort(vessel:any,element:any){
      if(this.count == 1){
        vessel['filterPort'] = element;
      }
    }

  getRepeatedVoyege(EPort: any) {
    let result = EPort.filter((arr: any, index: any, self: any) =>
      index === self.findIndex((t: any) => (t.idVoyageNumber === arr.idVoyageNumber)))
    let bookingDataArray = this.vesselData;
    let dupIndex: any = []
    for (let bookingValue of bookingDataArray) {
      for (let resultValue of result) {
        dupIndex.push(bookingValue.arPorts.map((i: any) => i.idVoyageNumber).indexOf(resultValue.idVoyageNumber))
      }
    }
    let getVal = dupIndex.filter((it: any) => it >= 0)
    if(result.length == 1){
      return [0]
    }else{
      return getVal 
    }
  }
  evaluateVessalData = async (data: TrackVessel[]) => {
    return data.map((e: TrackVessel) => ({
      ...e,
      'arVesselsLocation': e.arVesselsLocation.map((el: any) => ({ 'dtVesselTrackLastUpdatedOn': el.dtVesselTrackLastUpdatedOn, 'latitude': el.latitude, 'longitude': el.longitude })),
      'arPorts': this.mapCurrentPort(e.arPorts)
    }));
  }

  delay(val: any) {
    return Math.round(val);
  }

  setDelayData() {
    for (let index = 0; index < 3; index++) {
      if (index == 0) {
        this.delayObj.push({ 'min': -365, 'max': this.minDelay, cls: 'success' })
      }
      if (index == 1) {
        this.delayObj.push({ 'min': this.minDelay, 'max': this.maxDelay, cls: 'progress' })
      }
      if (index == 2) {
        this.delayObj.push({ 'min': this.maxDelay, 'max': 365, cls: 'error' })
      }

    }
  }

  filterVessalLocation(i: number) {
    this.clickedVessalLocation = [];
    this.clickedVessalLocation.push(this.arVesselTrack[i]);
  }

  latlongtoRadian(lt: any, ln: any) {
    return getLatLong(lt, ln);
  }

  mobileModelAddSource(event: Event,type:any,quentity?:any,docType?:any) {
    console.log("quentity",quentity)
    event.stopPropagation();       
    this.overFlowClass = true
    this.modelType = type
    this.renderer.addClass(this.filterClass.nativeElement, 'FilterOverlay');
    this.renderer.addClass(this.filterTooltipModelClass.nativeElement, 'ModelShow');

  }

  mobileModelClassClose(event:any){
    // this.mobileModelRemoveSource()
    this.overFlowClass = false
    this.renderer.removeClass(this.filterClass.nativeElement, 'FilterOverlay');
    this.renderer.removeClass(this.filterTooltipModelClass.nativeElement, 'ModelShow');
  }
 

  removeFilter(event: Event) {
    this.overFlowClass = false
    this.renderer.removeClass(this.filterClass.nativeElement, 'FilterOverlay');
    this.renderer.removeClass(this.filterTooltipModelClass.nativeElement, 'ModelShow');
    event.stopPropagation();
  }

}
