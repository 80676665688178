import { Component, Renderer2} from '@angular/core';

@Component({
  selector: 'app-user-survey-demo',
  templateUrl: './user-survey-demo.component.html',
  styleUrls: ['./user-survey-demo.component.scss']
})
export class UserSurveyDemoComponent {
  isClose:boolean = true;
  isSurvey:boolean = false;
  private scriptElement: HTMLScriptElement;
  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {
    this.loadSurveyMonkeyScript();

   setTimeout(()=>{
    this.isSurvey = true;
   },1000)
  }

  loadSurveyMonkeyScript(): void {
    this.scriptElement = this.renderer.createElement('script');
    this.scriptElement.type = 'text/javascript';
    this.scriptElement.async = true;
    this.scriptElement.id = 'smcx-sdk';
    this.scriptElement.src = 'https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgd31NvhWg5JgpuniyY4p8JHi_2Bj2xY00tfJIey7MyPyxlY.js';
    this.renderer.appendChild(document.body, this.scriptElement);
    
    setTimeout(() => {
      const modalCloseButtons = document.querySelectorAll('.smcx-modal-close');
      console.log('modalCloseButtons:', modalCloseButtons);
      
      modalCloseButtons.forEach((button: any) => {
        // Check if the button is found
        if (button) {
          // Add click event listener using Renderer2
          this.renderer.listen(button, 'click', () => {
            console.log('Modal close button clicked');
          });
          
          // Simulate a click event
          button.click(); // Automatically click the button
        }
      });
    }, 1000); // Adjust the delay as necessary
  }
  
}
