<!-- Header Section: This section represents the header of the webpage -->
<app-about-header></app-about-header>

<main class="StoltMainContent">
    <!-- Common page title section -->
    <div class="CommonHeroArea ContactTitleArea position-relative">
        <div class="LargeWindowWidth">
            <div class="CommonHeroContent ">
                <h1 class="heading01">User Survey</h1>
            </div>
        </div>
    </div>

    <!-- Common Content Section -->
    <section class="CommonContentSec">
        <div class="LargeWindowWidth" style="height: 300px;">
            <div id="surveyMonkeyWidget" class="df-c surveyContainerMn">
                <a (click)="isClose = true" class="BtnNoBgArrow position-relative m-0 fw-700">
                    Click here to take the Survey
                    <img src="assets/svg/login-arrow.svg" alt="login arrow">
                </a>
            </div>
        </div>

        <div *ngIf="isSurvey" class="smcx-widget smcx-modal smcx-modal-survey smcx-show smcx-widget-dark smcx-opaque" [ngClass]="{'smcx-hide-branding': isClose,'smcx-hide': !isClose }">
            <div class="smcx-modal-header">
                <div smcx-modal-headline="" class="smcx-modal-title">Welcome! We Value Your Feedback</div>
                <div smcx-modal-close="" class="smcx-modal-close-custom" (click)="isClose = false"></div>
            </div>
            <div class="smcx-modal-content">
                <div class="smcx-iframe-container"><iframe width="100%" height="100%" title="SurveyMonkey Survey" 
                    src="https://www.surveymonkey.com/r/YH2R775?embedded=1"></iframe></div>
            </div>
            <div class="smcx-widget-footer smcx-modal-footer"><a class="smcx-branding"
                    href="https://www.surveymonkey.com/?ut_source=powered_by&amp;ut_source2=new_website_collector"
                    target="_blank"><span class="smcx-powered-by">powered by</span></a></div>
        </div>
    </section>
</main>

<!-- Footer Section: This section represents the footer of the webpage -->
<app-footer></app-footer>