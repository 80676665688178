import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EmailSharing } from 'src/app/enums/inProgressFilter';
import { bccSepEmail, ccSepEmail, toSepEmail } from 'src/app/helpers/multipleEmail';
import { BookingService } from 'src/app/services/booking.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { StorageService } from 'src/app/services/storage.service';

@Component({
  selector: 'app-share-notification',
  templateUrl: './share-notification.component.html',
  styleUrls: ['./share-notification.component.scss']
})
export class ShareNotificationComponent {
  strEmails: any = '';
  strEmailsCC: any = '';
  strEmailsBcc: any = ''
  shareEmailTemp: UntypedFormGroup;
  isDisabledForm = false;
  isCc = false;
  isBcc = false;
  @Output() emitClaseNoti: EventEmitter<any> = new EventEmitter();
  @Output() savingProcessStatus: EventEmitter<any> = new EventEmitter();
  @Output() checkDupleicateEmail: EventEmitter<any> = new EventEmitter();
  @Output() hideShowNoticationStatus: EventEmitter<any> = new EventEmitter();
  @Output() shareEmailEmit: EventEmitter<boolean> = new EventEmitter();
  @Input() shareCompData: any[];
  @Input() PocData: any;
  @Input() shareType: any;
  @Input() editRowNotification:any
  DistSourceList: any
  Distributiondata: any;
  recipientEmail: any;
  listEnable: boolean = true;
  DistributiondataCC: any;
  DistributiondataBcc: any;
  listEnableCC: boolean = true;
  listEnableBcc: boolean = true;
  userEmail: any;
  checkHash: any;
  stoltDomain: any
  isNotification = true;
  defaulyTimezone: string = 'Monrovia, Reykjavik, (GMT+00:00)';
  BookingLevelData: { bookingId: any[]; bookingLevelEmail: any; };
  notificationChanges: any;
  daysInvalid: boolean;
  showHideNotifiction: boolean = false;
  viewMode:string=''
  constructor(private fb: UntypedFormBuilder, private navigationService: NavigationService, private router: Router, private bookingService: BookingService, private _storageService: StorageService) {
    this.getProfile();
    this.getDistributionlist()
    this.navigationService.setShowNav(true);
    this.navigationService.setMobileNav(false);
    this.navigationService.setPageTitle('Stolt Tankers-Setting');
    this.shareEmailTemp = this.fb.group({
      'toAddress': ["", Validators.compose([
        Validators.required, Validators.pattern(/^([\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})(, ?[\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4}){0,2}$/), toSepEmail
      ]),],
      'ccAddress': ["", Validators.compose([
        Validators.pattern(/^([\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})(, ?[\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4}){0,2}$/), ccSepEmail
      ]),],
      'bccAddress': ["", Validators.compose([
        Validators.pattern(/^([\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})(, ?[\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4}){0,2}$/), bccSepEmail
      ]),],
      'subject': [{ value: ` has shared a booking with you`, disabled: true }, Validators.compose([
        Validators.required])],
      'context': [{ value: ``, disabled: true }, Validators.compose([
        Validators.required])],
      'selfemail': ["",]
    });
  }

  ngOnInit() {
    this.viewMode = this.shareCompData.length ? this.shareCompData[0].bookingStatus : 'Ongoing';
    if(this.editRowNotification && this.editRowNotification.id){
      let bccEmail = this.editRowNotification.bcc_email.length > 0 ? this.editRowNotification.bcc_email.filter((item:any) => item !==   this.editRowNotification.email) : []
      this.shareEmailTemp = this.fb.group({
        'toAddress': [this.editRowNotification.bookingLevelUserEmail, Validators.compose([
          Validators.required, Validators.pattern(/^([\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})(, ?[\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4}){0,2}$/), toSepEmail
        ]),],
        'ccAddress': [this.editRowNotification.cc_email.toString(), Validators.compose([
          Validators.pattern(/^([\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})(, ?[\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4}){0,2}$/), ccSepEmail
        ]),],
        'bccAddress': [bccEmail.toString(), Validators.compose([
          Validators.pattern(/^([\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})(, ?[\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4}){0,2}$/), bccSepEmail
        ]),],
        'subject': [{ value: ` has shared a booking with you`, disabled: true }, Validators.compose([
          Validators.required])],
        'context': [{ value: ``, disabled: true }, Validators.compose([
          Validators.required])],
        'selfemail': [this.editRowNotification.selfEmail]
      });
    }else{
      this.shareEmailTemp = this.fb.group({
        'toAddress': ["", Validators.compose([
          Validators.required, Validators.pattern(/^([\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})(, ?[\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4}){0,2}$/), toSepEmail
        ]),],
        'ccAddress': ["", Validators.compose([
          Validators.pattern(/^([\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})(, ?[\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4}){0,2}$/), ccSepEmail
        ]),],
        'bccAddress': ["", Validators.compose([
          Validators.pattern(/^([\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})(, ?[\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4}){0,2}$/), bccSepEmail
        ]),],
        'subject': [{ value: ` has shared a booking with you`, disabled: true }, Validators.compose([
          Validators.required])],
        'context': [{ value: ``, disabled: true }, Validators.compose([
          Validators.required])],
        'selfemail': [false]
      });
    }
   
    this.callOnBookingLevel()
    this.stoltDomain = localStorage.getItem("stoltDomain")
    this.shareCompData = this.shareCompData.map((e) => {
      return {
        vessel: [...new Set(e.vessel.split(", "))].join(", ")
        , id: e.id, eta: e.eta, fleet: e.fleet, idCargoes: e.idCargoes, bookingStatus: e.bookingStatus, booking_fleet: e.booking_fleet,loadPortETA:e.loadPortETA,dischargePortETA:e.dischargePortETA
      }
    })
  }

  callOnBookingLevel() {
    this.shareEmailTemp.statusChanges.subscribe(value => {
      this._storageService.shareEmailStatus.next(value)
    });

    this.shareEmailTemp.valueChanges.subscribe(value => {
      this.shareEmailEmit.emit(value)
    });
  }



  getDistributionlist() {
    this.bookingService.getEmailDistribution().subscribe({next:(res: any) => {
      this.DistSourceList = res.data;
    }, error:err => {
      this.DistSourceList = []
    }})
  }

  getProfile() {
    this.navigationService.getUserInfo().subscribe({next:(res: any) => {
      let givenName = res.data && res.data.charterer ? res.data.charterer : 'Customer';
      let username = res.data && res.data.given_name ? res.data.given_name : ''
      this.userEmail = res.data && res.data.email ? res.data.email : ''
      this.shareEmailTemp.controls['subject'].setValue(`${username} from ${givenName} has shared a booking with you`);
      if (!res?.data?.company_no?.length) {
        this._storageService.putDataInStorage("isNotCompanyAssigned", true);
        this.router.navigate(['/'])
      } else {
        this._storageService.putDataInStorage("isNotCompanyAssigned", false);
      }
    }, error:(err: any) => {
      this.navigationService.errorOccure(err);
    }});
  }



  shareDetail(selectedEvent?:any) {
    this.isDisabledForm = true;
    const shareTempValue = this.shareEmailTemp.getRawValue();
    let selfEmail = shareTempValue.selfemail;
    let toAddressArray = (shareTempValue.toAddress.toLowerCase().trim().split(',')).map((it: any) => it.trim().split(';'))
    let ccAddressArray = (shareTempValue.ccAddress.toLowerCase().trim().split(',')).map((it: any) => it.trim().split(';'));
    let bccAddressArray = (shareTempValue.bccAddress.toLowerCase().trim().split(',')).map((it: any) => it.trim().split(';'));
    toAddressArray = toAddressArray.flat().filter((item: string) => item);
    ccAddressArray = ccAddressArray.flat().filter((item: string) => item);
    bccAddressArray = bccAddressArray.flat().filter((item: string) => item);
    toAddressArray = toAddressArray.length > 0 ? toAddressArray.filter((x: string, i: number, a: any) => a.indexOf(x) === i) : [];
    ccAddressArray = ccAddressArray.length > 0 ? ccAddressArray.filter((x: string, i: number, a: any) => a.indexOf(x) === i) : [];
    bccAddressArray = bccAddressArray.length > 0 ? bccAddressArray.filter((x: string, i: number, a: any) => a.indexOf(x) === i) : [];
    if (selfEmail)
      toAddressArray.push(this.userEmail.toLowerCase())

    if ((ccAddressArray.length > 0 && toAddressArray.length > 0 && toAddressArray.filter((value: string) => ccAddressArray.includes(value)).length > 0)) {
      this.isDisabledForm = false;
      const err = {
        code: 406,
        statusText: 'Not Acceptable',
        error: { error: { description: 'Duplicate email exist in To/CC' } }
      }
      this.checkDupleicateEmail.emit(true)
      this.navigationService.errorOccure(err);
    } else if ((toAddressArray.length > 0 && bccAddressArray.length > 0 && toAddressArray.filter((value: string) => bccAddressArray.includes(value)).length > 0)) {
      this.isDisabledForm = false;
      const err = {
        code: 406,
        statusText: 'Not Acceptable',
        error: { error: { description: 'Duplicate email exist in To/BCC' } }
      }
      this.checkDupleicateEmail.emit(true)
      this.navigationService.errorOccure(err);
    } else if ((ccAddressArray.length > 0 && bccAddressArray.length > 0 && ccAddressArray.filter((value: string) => bccAddressArray.includes(value)).length > 0)) {
      this.isDisabledForm = false;
      const err = {
        code: 406,
        statusText: 'Not Acceptable',
        error: { error: { description: 'Duplicate email exist in CC/BCC' } }
      }
      this.checkDupleicateEmail.emit(true)
      this.navigationService.errorOccure(err);

    } else if (toAddressArray.length >= 50 || ccAddressArray.length >= 50 || bccAddressArray.length >= 50) {
      this.isDisabledForm = false;
      const err = {
        code: 406,
        statusText: 'Not Acceptable',
        error: { error: { description: 'To,Cc(optional),Bcc(optional) Maxlength should be 50' } }
      }
      this.checkDupleicateEmail.emit(true)
      this.navigationService.errorOccure(err);
    }
    else {
      this.savingProcessStatus.emit(false)
      this.checkDupleicateEmail.emit(false)
      this.hitShareDetails(selectedEvent, ccAddressArray,toAddressArray,bccAddressArray);
    }
  }

  hitShareDetails(selectedEvent:any,ccAddressArray:any, toAddressArray:any, bccAddressArray:any){
    if(this.shareType == 'booking'){
      this.refactorSaveShareBookingEmail(ccAddressArray, toAddressArray, bccAddressArray,selectedEvent)
    }
  }

  refactorSaveShareBookingEmail(ccAddressArray: any, toAddressArray: any, bccAddressArray: any,selectedEvent?:any) {
    this.shareCompData = this.shareCompData.map((i: any) => ({
      'fleet': i.fleet, 'id': i.id, 'idCargoes': i.idCargoes,
      'eta': this.getEta(i.eta),
      'bookingStatus': this.getbookingStatus(i.bookingStatus),
      'loadPortETA' : i.loadPortETA,
      'dischargePortETA' : i.dischargePortETA
    }))
    let emailObj: { "toAddress": [], "ccAddress"?: [], "bccAddress"?: [], /* "subject": string, */ "context": any[] }
      = {
      "toAddress": toAddressArray,
      "context": this.shareCompData
    };

    emailObj.ccAddress = ccAddressArray.length > 0 ? ccAddressArray : [];
    emailObj.bccAddress = bccAddressArray.length > 0 ? bccAddressArray : [];
    this.bookingService.sendmail(emailObj).subscribe({next:(res: any) => {
    if(!selectedEvent.ChangeShipNom && !selectedEvent.etaUpdates && !selectedEvent.ShipArrivalLaycanPeriod && 
        !selectedEvent.allShipmentETAs){
          let msgSuccess = 'Booking(s) are successfully shared.';
          this.navigationService.showSuccess(`${msgSuccess}`, 'Email Sent');
        }
      this.isDisabledForm = false;
      this.emitClaseNoti.emit({ event: false, type: 'save' });
      this.savingProcessStatus.emit(true)
    }, error:(err: any) => {
      this.emitClaseNoti.emit({ event: false, type: 'save' });
      this.savingProcessStatus.emit(true)
      this.isDisabledForm = false;
      this.navigationService.errorOccure(err);
    }})
  }


  getbookingStatus(bookingStatus: any) {
    if (bookingStatus === 'Ongoing') {
      return 1;
    } else if (bookingStatus === 'Completed') {
      return 2;
    }else if(bookingStatus === 'Nominations') {
      return 4;
    } else {
      return 3;
    }
  }

  getEta(eta: any) {
    if (eta === 'Early Arrival') {
      return 1;
    } else if (eta === 'On time') {
      return 2;
    } else {
      return 3;
    }
  }




  FetchDistData(event: any, type: any) {
    this.isDisabledForm = false;
    let PicHash = event.target.value;
    this.checkHash = PicHash.includes('#');
    let comaSeprate = PicHash.includes(',#');
    let semiColSerate = PicHash.includes(';#');
    switch (type) {
      case EmailSharing.toObj:
        if (PicHash == '#' || comaSeprate || semiColSerate) {
          this.listEnable = true
          this.Distributiondata = this.DistSourceList;
        }
        else {
          this.listEnable = false
          this.Distributiondata = [];
        }
        break;
      case EmailSharing.ccObj:
        if (PicHash == '#' || comaSeprate || semiColSerate) {
          this.listEnableCC = true;
          this.DistributiondataCC = this.DistSourceList
        }
        else {
          this.listEnableCC = false;
          this.DistributiondataCC = [];
        }
        break
      default:
        if (PicHash == '#' || comaSeprate || semiColSerate) {
          this.listEnableBcc = true;
          this.DistributiondataBcc = this.DistSourceList
        }
        else {
          this.listEnableBcc = false;
          this.DistributiondataBcc = [];
        }
        break;
    }

  }

  getListEmails(event: any, type: any) {
    switch (type) {
      case EmailSharing.toObj:
        this.listEnable = false;
        let respemail: any = [];
        event.forEach((err: any) => {
          respemail.push(err.email)
        })
        this.strEmails = this.strEmails.concat(respemail.toString()).replace('#', '')
        this.shareEmailTemp.get("toAddress")?.patchValue(this.strEmails)
        break;
      case EmailSharing.ccObj:
        this.listEnableCC = false;
        let respemailcc: any = [];
        event.forEach((err: any) => {
          respemailcc.push(err.email)
        })
        this.strEmailsCC = this.strEmailsCC.concat(respemailcc.toString()).replace('#', '')
        this.shareEmailTemp.get("ccAddress")?.patchValue(this.strEmailsCC)
        break
      default:
        this.listEnableBcc = false;
        let respemailBcc: any = [];
        event.forEach((err: any) => {
          respemailBcc.push(err.email)
        })
        this.strEmailsBcc = this.strEmailsBcc.concat(respemailBcc.toString()).replace('#', '')
        this.shareEmailTemp.get("bccAddress")?.patchValue(this.strEmailsBcc)
        break;
    }
  }

  getSelfEmail(event:any){
    if((event.target as HTMLInputElement).checked){
      this.shareEmailTemp.get("selfemail")?.patchValue(this.userEmail.toLowerCase())
    }else{
      this.shareEmailTemp.get("selfemail")?.patchValue('')
    }
  }

  showHideNotification(event:any){
    this.showHideNotifiction = (event.target as HTMLInputElement).checked;
    this.hideShowNoticationStatus.emit(this.showHideNotifiction)
  }

}
