import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModuleRoutingModule } from './shared-module-routing.module';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { SharedMaterialModule } from '../../shared-material/shared-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotificationSetupComponent } from './notification-setup/notification-setup.component';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { NgSelectModule } from '@ng-select/ng-select';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ShareNotificationComponent } from './share-notification/share-notification.component';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { SharedSnlComplibModule } from '../../shared-material/shared-snl-complib-module';
import { NotificationSetupPocComponent } from './notification-setup-poc/notification-setup-poc.component';
import { ShareFilterComponent } from './share-filter/share-filter.component';


@NgModule({
  declarations: [
    SearchBarComponent,
    NotificationSetupComponent,
    ShareNotificationComponent,
    NotificationSetupPocComponent,
    ShareFilterComponent
  ],
  imports: [
    CommonModule,
    SharedModuleRoutingModule,
    SharedMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,TimepickerModule.forRoot(), PopoverModule.forRoot(),
    AngularSvgIconModule,
    MomentDateModule,
    SharedSnlComplibModule
  ],
  exports:[
    SearchBarComponent,
    NotificationSetupComponent,
    ShareNotificationComponent,
    NotificationSetupPocComponent,
    ShareFilterComponent
  ]
})
export class SharedModuleModule { }
