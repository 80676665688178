
import { UntypedFormControl, Validators, AbstractControl,ValidatorFn} from "@angular/forms";
export function toSepEmail(control: AbstractControl): { [key: string]: any; } | null {
    const emails = control.value.split(';').map((e:any) => e.trim().split(','));
    const forbidden = emails.flat().some((email:any) => Validators.email(new UntypedFormControl(email.trim())));
    return forbidden ? { 'toAddress': { value: control.value } } : null;
}

export function ccSepEmail(control: AbstractControl): { [key: string]: any; } | null {
    const emails = control.value.split(';').map((e:any) => e.trim().split(','));
    const forbidden = emails.flat().some((email:any) => Validators.email(new UntypedFormControl(email.trim())));
    return forbidden ? { 'ccAddress': { value: control.value } } : null;
}

export function bccSepEmail(control: AbstractControl): { [key: string]: any; } | null {
    const emails = control.value.split(';').map((e:any) => e.trim().split(','));
    const forbidden = emails.flat().some((email:any) => Validators.email(new UntypedFormControl(email.trim())));
    return forbidden ? { 'bccAddress': { value: control.value } } : null;
}

export function domainValidator(domainName: any,isAdmin:any): ValidatorFn {
    console.log("domain",domainName)
    return (control: AbstractControl): { [key: string]: any } | null => {
      const email: string = control.value;
      let domain = ''
      if(email && domainName && isAdmin == 'admin'){
         domain = control.value.substring(control.value.lastIndexOf('@') + 1);
         if (domain.toLowerCase() != domainName.toLowerCase()) {
            return { 'checkDomain': true };
        }
      }
        return null;
    };
}

export function brokerAccessValidator(brokerList:any) {
    return (control: AbstractControl): { [key: string]: any } | null => {
        let broker = control.value?control.value:''
        let checkBroker = brokerList.filter((item:any) => item.broker == broker)
        if(!control.value || !checkBroker.length){
          return {"brokerErr":true}
        }
        return null
    }
    
  }