import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AboutComponent} from "./front/pages/about/about.component";
import {FleetInfoComponent} from "./front/pages/fleet-info/fleet-info.component";
import {FleetInfoQ88Component} from "./front/pages/fleet-info-q88/fleet-info-q88.component";
import { ContactComponent } from "./front/pages/contact/contact.component";
import {HomeComponent} from "./front/pages/home/home.component";
import {TrackComponent} from "./front/pages/track/track.component";
import { TermOfUseComponent} from "./front/pages/term-of-use/term-of-use.component";
import { PrivacyPolicyComponent} from "./front/pages/privacy-policy/privacy-policy.component";
import { AccessibilityComponent} from "./front/pages/accessibility/accessibility.component";
import { FourZeroFourComponent} from "./front/pages/four-zero-four/four-zero-four.component";
import { NgSelectModule } from '@ng-select/ng-select';
import { FleetInfoIframeComponent } from './front/pages/fleet-info-iframe/fleet-info-iframe.component';
import { ContactIframeComponent } from './front/pages/contact-iframe/contact-iframe.component';
import { OurfleetExternalComponent } from './front/pages/ourfleet-external/ourfleet-external.component';
import { FleetInfoQ88IframeComponent } from './front/pages/fleet-info-q88-iframe/fleet-info-q88-iframe.component';
import { SnitsComponent } from './front/pages/snits/snits.component';
import { EnquiryFormComponent } from './front/pages/enquiry-form/enquiry-form.component';
import { GlobalSearchComponent } from './front/pages/global-search/global-search.component';
import { DownloadDocumentComponent } from './front/pages/download-document/download-document.component';
import { SettingPocComponent } from './front/pages/setting-poc/setting-poc.component';
import { MsalGuard } from '@azure/msal-angular';
const routes: Routes = [
  {
    path: "about",
    component: AboutComponent
  },
  {
    path: 'fleetinfo',
    component: FleetInfoComponent,
   // canActivate: [AuthGuard]
  },
  {
    path: 'Q88/:id',
    component: FleetInfoQ88Component,
   // canActivate: [AuthGuard]
  },
  {
    path: 'contact',
    component: ContactComponent,
  },
  {
    path: 'setting',
    component: SettingPocComponent,
  },
  {
    path: 'setting-snl-poc',
    component: SettingPocComponent,
  },
  {
    path: 'search',
    component: GlobalSearchComponent,
  },
  {
    path: 'track/:id',
    component: TrackComponent,
  },
  {
    path: "booking",
    loadChildren: () => import("./front/pages/pages.module").then(m => m.PagesModule)
  },
  {
    path: "terms-of-use",
    component: TermOfUseComponent
  },
  {
    path: "privacy-and-cookies",
    component: PrivacyPolicyComponent
  },
  {
    path: "accessibility",
    component: AccessibilityComponent
  },
  {
    path: 'fleetinfo-external',
    component: FleetInfoIframeComponent,
   // canActivate: [AuthGuard]
  },
  {
    path: 'contact-external',
    component: ContactIframeComponent,
  },
  {
    path: 'ourfleet-external',
    component: OurfleetExternalComponent,
  },
  {
    path: 'fleet/vessel',
    component: FleetInfoQ88IframeComponent,
  },
  {
    path: 'fleetinfo/snits',
    component: SnitsComponent,
    
    // data: {
    //   seo: {
    //     title: 'Fleet Info',
    //     metaTags: [
    //       // { name: 'description', content: 'Game of Thrones Quotes : Winter is Coming, You know nothing Jon Snow, Never forget what you are. The rest of the world will not. Wear it like armour, and it can never be used to hurt you' },
    //       { keywords: `Barge, barging, inland tanker, floating storage, Rhine, chemical tanker, Rhine transportation, stainless steel tanks, parcel tankers in Europe, storage tankers in Europe.`}
        
    //     ]
    //   }
    // }
  },
  {
    path: 'fleetinfo/enquiryform',
    component: EnquiryFormComponent,
  },
  {
    path: 'documets-download',
    component: DownloadDocumentComponent,
  },
  {
    path: '404',
    component: FourZeroFourComponent,
  },
  {
    path: '',
    component: HomeComponent,
  },
  { path: '', pathMatch: 'full', redirectTo: '' },
  {path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{scrollPositionRestoration: 'enabled'}),NgSelectModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
