import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { toSepEmail, bccSepEmail, ccSepEmail } from '../../../helpers/multipleEmail';
import { BookingService } from '../../../services/booking.service';
import { NavigationService } from '../../../services/navigation.service';
import { environment } from '../../../../environments/environment';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { EmailSharing } from 'src/app/enums/inProgressFilter';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/services/storage.service';
@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss']
})
export class ShareComponent implements OnInit {
  isDisabledForm = false;
  /*----------html editor-----------*/
  editorConfig: AngularEditorConfig = {
    editable: false,
    spellcheck: true,
    showToolbar: false,
    height: '15rem',
    minHeight: '5rem',
    placeholder: '',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [['bold']],
    sanitize: false,
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };
  shareEmailTemp: UntypedFormGroup;
  isCc = false;
  isBcc = false;
  @Output() emitShare: EventEmitter<boolean> = new EventEmitter();
  @Output() hideShowNoticationStatus: EventEmitter<any> = new EventEmitter();
  @Input() shareCompData: any[];
  @Input() viewMode: any;
  @Input() PocData: any;
  @Input() DocData: any;
  @Input() shareType: any;
  @Input() shareSusData: any
  @Input() inDocAllCheckBox:boolean
  DistSourceList: any
  Distributiondata: any;
  recipientEmail: any;
  strEmails: any = '';
  listEnable: boolean = true;
  DistributiondataCC: any;
  DistributiondataBcc: any;
  listEnableCC: boolean = true;
  strEmailsCC: any = '';
  listEnableBcc: boolean = true;
  strEmailsBcc: any = '';
  userEmail: any;
  checkHash: any;
  stoltDomain: any
  isNotification = true;
  distributionRemoveModel:boolean = true
  defaulyTimezone: string = 'Monrovia, Reykjavik, (GMT+00:00)';
  BookingLevelData: { bookingId: any[]; bookingLevelEmail: any; };
  notificationChanges: any;
  snlModalSettings = { showCloseIcon: true, backdropClick: false }
  showHideNotifiction: boolean = false;
  constructor(private fb: UntypedFormBuilder, private navigationService: NavigationService, private router: Router, private bookingService: BookingService, 
    private _storageService: StorageService) {
    this.getProfile();
    this.getDistributionlist()
    this.shareEmailTemp = this.fb.group({
      'toAddress': ["", Validators.compose([
        Validators.required, Validators.pattern(/^([\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})(, ?[\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4}){0,2}$/), toSepEmail
      ]),],
      'ccAddress': ["", Validators.compose([
        Validators.pattern(/^([\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})(, ?[\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4}){0,2}$/), ccSepEmail
      ]),],
      'bccAddress': ["", Validators.compose([
        Validators.pattern(/^([\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})(, ?[\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4}){0,2}$/), bccSepEmail
      ]),],
      'subject': [{ value: ` has shared a booking with you`, disabled: true }, Validators.compose([
        Validators.required])],
      'context': [{ value: ``, disabled: true }, Validators.compose([
        Validators.required])],
      'selfemail': ["",]
    });
  }

  ngOnInit() {
    this.stoltDomain = localStorage.getItem("stoltDomain")
    this.shareCompData = this.shareCompData.map((e) => {
      return {
        vessel: [...new Set(e.vessel.split(", "))].join(", ")
        , id: e.id, eta: e.eta, fleet: e.fleet, idCargoes: e.idCargoes, bookingStatus: e.bookingStatus, booking_fleet: e.booking_fleet,loadPortETA:e.loadPortETA,dischargePortETA:e.dischargePortETA
      }
    })
  }




  getDistributionlist() {
    this.bookingService.getEmailDistribution().subscribe({next:(res: any) => {
      this.DistSourceList = res.data;
    }, error:err => {
      this.DistSourceList = []
    }})
  }

  getProfile() {
    this.navigationService.getUserInfo().subscribe({next:(res: any) => {
      let givenName = res.data && res.data.charterer ? res.data.charterer : 'Customer';
      let username = res.data && res.data.given_name ? res.data.given_name : ''
      this.userEmail = res.data && res.data.email ? res.data.email : ''
      this.shareEmailTemp.controls['subject'].setValue(`${username} from ${givenName} has shared a booking with you`);
      this.contextData(givenName);
      if (!res?.data?.company_no?.length) {
        this._storageService.putDataInStorage("isNotCompanyAssigned", true);
        this.router.navigate(['/'])
      } else {
        this._storageService.putDataInStorage("isNotCompanyAssigned", false);
      }
    }, error:(err: any) => {
      this.contextData('Customer');
      this.navigationService.errorOccure(err);
    }});
  }
  discard() {
    this.emitShare.emit(false);
  }


  shareDetail() {
    this.isDisabledForm = true;
    const shareTempValue = this.shareEmailTemp.getRawValue();
    let selfEmail = shareTempValue.selfemail;
    let toAddressArray = (shareTempValue.toAddress.toLowerCase().trim().split(',')).map((it: any) => it.trim().split(';'))
    let ccAddressArray = (shareTempValue.ccAddress.toLowerCase().trim().split(',')).map((it: any) => it.trim().split(';'));
    let bccAddressArray = (shareTempValue.bccAddress.toLowerCase().trim().split(',')).map((it: any) => it.trim().split(';'));
    toAddressArray = toAddressArray.flat().filter((item: string) => item);
    ccAddressArray = ccAddressArray.flat().filter((item: string) => item);
    bccAddressArray = bccAddressArray.flat().filter((item: string) => item);
    toAddressArray = toAddressArray.length > 0 ? toAddressArray.filter((x: string, i: number, a: any) => a.indexOf(x) === i) : [];
    ccAddressArray = ccAddressArray.length > 0 ? ccAddressArray.filter((x: string, i: number, a: any) => a.indexOf(x) === i) : [];
    bccAddressArray = bccAddressArray.length > 0 ? bccAddressArray.filter((x: string, i: number, a: any) => a.indexOf(x) === i) : [];
    if (selfEmail)
      toAddressArray.push(this.userEmail.toLowerCase())

    if ((ccAddressArray.length > 0 && toAddressArray.length > 0 && toAddressArray.filter((value: string) => ccAddressArray.includes(value)).length > 0)) {
      this.isDisabledForm = false;
      const err = {
        code: 406,
        statusText: 'Not Acceptable',
        error: { error: { description: 'Duplicate email exist in To/CC' } }
      }
      this.navigationService.errorOccure(err);
    } else if ((toAddressArray.length > 0 && bccAddressArray.length > 0 && toAddressArray.filter((value: string) => bccAddressArray.includes(value)).length > 0)) {
      this.isDisabledForm = false;
      const err = {
        code: 406,
        statusText: 'Not Acceptable',
        error: { error: { description: 'Duplicate email exist in To/BCC' } }
      }
      this.navigationService.errorOccure(err);
    } else if ((ccAddressArray.length > 0 && bccAddressArray.length > 0 && ccAddressArray.filter((value: string) => bccAddressArray.includes(value)).length > 0)) {
      this.isDisabledForm = false;
      const err = {
        code: 406,
        statusText: 'Not Acceptable',
        error: { error: { description: 'Duplicate email exist in CC/BCC' } }
      }
      this.navigationService.errorOccure(err);

    } else if (toAddressArray.length >= 50 || ccAddressArray.length >= 50 || bccAddressArray.length >= 50) {
      this.isDisabledForm = false;
      const err = {
        code: 406,
        statusText: 'Not Acceptable',
        error: { error: { description: 'To,Cc(optional),Bcc(optional) Maxlength should be 50' } }
      }
      this.navigationService.errorOccure(err);
    }
    else {
      this.refactorSaveShareBookingEmail(ccAddressArray, toAddressArray, bccAddressArray)
    }
  }

  refactorSaveShareBookingEmail(ccAddressArray: any, toAddressArray: any, bccAddressArray: any) {
    if (this.viewMode == 'Poc') {
      this.refactorSaveShareEmailPoc(ccAddressArray, toAddressArray, bccAddressArray)
    } else if (this.shareType == 'sustain') {
      this.refactorSaveSuastainbilityShareEmail(ccAddressArray, toAddressArray, bccAddressArray)
    }
    else if (this.shareType == 'docs') {
      this.refactorDocumentationShareEmail(ccAddressArray, toAddressArray, bccAddressArray)
    } else {
      this.refactorSaveShareEmail(ccAddressArray, toAddressArray, bccAddressArray)
    }
  }

  refactorSaveShareEmailPoc(ccAddressArray: any, toAddressArray: any, bccAddressArray: any) {
    const bookingId = this.PocData.bookingId ?  this.PocData.bookingId :''   
    const id_fleet = this.PocData.id_fleet ? this.PocData.id_fleet : ''
    // this.PocData = this.PocData.map((ele: any) => ({
    //   documentName: ele.documentType,
    //   documentLink: `${environment.DOCUMENTATION_BASE_URL}?api-key=${environment.DOCUMENTATION_POC_KEY}&doc-key=${ele.downloadKey}`,
    //   documentStatus: ele.documentStatus, fname: ele.name.replace(" / ", '_')
    // }))
    /* share email from documentions */ 
    this.PocData = Object.values(this.PocData.reduce((item:any, next:any) => {
      if (!item[next.cargoId]) {
          item[next.cargoId] = {
              cargoName: next.cargoName,
              docs: []
          };
      }      
      item[next.cargoId].docs.push({
        documentName: next.documentType,
        documentLink: `${environment.DOCUMENTATION_BASE_URL}?api-key=${environment.DOCUMENTATION_POC_KEY}&doc-key=${next.downloadKey}`,
        documentStatus: next.documentStatus, fname: next.name.replace(" / ", '_')
      });
      return item;
      }, {}));
    let emailObj: { "toAddress": [], "ccAddress"?: [], "bccAddress"?: [], /* "subject": string, */ "contextDoc": any[], "bookingId": any,id_fleet:any }
      = {
      "toAddress": toAddressArray,
      "contextDoc": this.PocData,
      "bookingId": bookingId,
      "id_fleet" : id_fleet
    };
    if(!this.inDocAllCheckBox)
      delete emailObj['id_fleet']
    
    emailObj.ccAddress = ccAddressArray.length > 0 ? ccAddressArray : [];
    emailObj.bccAddress = bccAddressArray.length > 0 ? bccAddressArray : [];
   
    this.bookingService.sendDocumentEmail(emailObj).subscribe({next:(res: any) => {
      let msgSuccess = 'Document(s) are sucessfully shared.';
      this.navigationService.showSuccess(`${msgSuccess}`, 'Email Sent');
      this.isDisabledForm = false;
      this.emitShare.emit(false);
    }, error:(err: any) => {
      this.isDisabledForm = false;
      this.navigationService.errorOccure(err);
      this.emitShare.emit(false);
    }})
  }

  refactorSaveShareEmail(ccAddressArray: any, toAddressArray: any, bccAddressArray: any) {

    if (this.shareType == 'booking') {
      let bookId = this.shareCompData.map((it: any) => it.booking_fleet)
      this.BookingLevelData = { bookingId: bookId, bookingLevelEmail: toAddressArray }
    }

    this.shareCompData = this.shareCompData.map((i: any) => ({
      'fleet': i.fleet, 'id': i.id, 'idCargoes': i.idCargoes,
      'eta': this.getEta(i.eta),
      'bookingStatus': this.getbookingStatus(i.bookingStatus),
      'loadPortETA' : i.loadPortETA,
      'dischargePortETA' : i.dischargePortETA
    }))
    let emailObj: { "toAddress": [], "ccAddress"?: [], "bccAddress"?: [], /* "subject": string, */ "context": any[] }
      = {
      "toAddress": toAddressArray,
      "context": this.shareCompData,
    };

    emailObj.ccAddress = ccAddressArray.length > 0 ? ccAddressArray : [];
    emailObj.bccAddress = bccAddressArray.length > 0 ? bccAddressArray : [];
    this.bookingService.sendmail(emailObj).subscribe({next:(res: any) => {
      let msgSuccess = 'Booking(s) are successfully shared.';
      this.navigationService.showSuccess(`${msgSuccess}`, 'Email Sent');
      this.isDisabledForm = false;
      this.emitShare.emit(false);
    }, error:(err: any) => {
      this.isDisabledForm = false;
      this.navigationService.errorOccure(err);
      this.emitShare.emit(false);
    }})
  }
  refactorDocumentationShareEmail(ccAddressArray: any, toAddressArray: any, bccAddressArray: any) {
    let contextDoc: any = [];
    let bookingId
    let id_fleet
    this.DocData.forEach((doc: any) => (
      id_fleet = doc.id_fleet,
      bookingId=doc.bookingId+'',
      doc.vesseldata.forEach((arPort: any) => {
        arPort.loadDocs.forEach((loadDoc: any) => {
          loadDoc.dockeys.map((key: any) => (contextDoc.push({
            cargoId:loadDoc.cargoId,
            cargoName:loadDoc.name,
            documentName: key.documentType,//key.documentType,
            documentLink: `${environment.DOCUMENTATION_BASE_URL}?api-key=${environment.DOCUMENTATION_POC_KEY}&doc-key=${key.downloadKey}`,
            documentStatus: 'loading',
            fname: key.name.replace(" / ", '_')
          })))
        })
        arPort.dischargeDocs.forEach((dischargeDoc: any) => {
          dischargeDoc.dockeys.map((key: any) => (contextDoc.push({
            cargoId:dischargeDoc.cargoId,
            cargoName:dischargeDoc.name,
            documentName: key.documentType,//key.documentType,
            documentLink: `${environment.DOCUMENTATION_BASE_URL}?api-key=${environment.DOCUMENTATION_POC_KEY}&doc-key=${key.downloadKey}`,
            documentStatus: 'discharging',
            fname: key.name.replace(" / ", '_')
          })))
        })
      })
    ))
    /* share email from documentions */
    contextDoc= Object.values(contextDoc.reduce((item:any, next:any) => {
      if (!item[next.cargoId]) {
          item[next.cargoId] = {
              cargoName: next.cargoName,
              docs: []
          };
      }
      item[next.cargoId].docs.push({
        documentName: next.documentName,
        documentLink: next.documentLink,
        documentStatus: next.documentStatus, fname: next.fname
      });
      return item;
      }, {})); 
    let emailObj: { "toAddress": [], "ccAddress"?: [], "bccAddress"?: [], /* "subject": string, */ "bookingId":any, "contextDoc": any[],id_fleet:any }
      = {
      "toAddress": toAddressArray,
      "contextDoc": contextDoc,
      "bookingId":bookingId,
      "id_fleet" : id_fleet
    };
    emailObj.ccAddress = ccAddressArray.length > 0 ? ccAddressArray : [];
    emailObj.bccAddress = bccAddressArray.length > 0 ? bccAddressArray : [];

    this.bookingService.sendDocumentEmail(emailObj).subscribe({next:(res: any) => {
      let msgSuccess = 'Document(s) are sucessfully shared.';
      this.navigationService.showSuccess(`${msgSuccess}`, 'Email Sent');
      this.isDisabledForm = false;
      this.emitShare.emit(false);
    }, error:(err: any) => {
      this.isDisabledForm = false;
      this.navigationService.errorOccure(err);
      this.emitShare.emit(false);
    }})
  }
  refactorSaveSuastainbilityShareEmail(ccAddressArray: any, toAddressArray: any, bccAddressArray: any) {
    this.shareSusData = this.shareSusData.map((i: any) => ({
      'fleet': i.fleet,
      'id': i.id
    }))
    let emailObj: { "toAddress": [], "ccAddress"?: [], "bccAddress"?: [], /* "subject": string, */ "context": any[] }
      = {
      "toAddress": toAddressArray,
      "context": this.shareSusData
    };

    emailObj.ccAddress = ccAddressArray.length > 0 ? ccAddressArray : [];
    emailObj.bccAddress = bccAddressArray.length > 0 ? bccAddressArray : [];

    this.bookingService.sendSustainabilityMail(emailObj).subscribe({next:(res: any) => {
      let msgSuccess = 'Booking(s) are successfully shared.';
      this.navigationService.showSuccess(`${msgSuccess}`, 'Email Sent');
      this.isDisabledForm = false;
      this.emitShare.emit(false);
    }, error:(err: any) => {
      this.isDisabledForm = false;
      this.navigationService.errorOccure(err);
      this.emitShare.emit(false);
    }})
  }

  getbookingStatus(bookingStatus: any) {
    if (bookingStatus === 'Ongoing') {
      return 1;
    } else if (bookingStatus === 'Completed') {
      return 2;
    } else if (bookingStatus === 'Nominations') {
      return 4;
    } else {
      return 3;
    }
  }

  getEta(eta: any) {
    if (eta === 'Early Arrival') {
      return 1;
    } else if (eta === 'On time') {
      return 2;
    } else {
      return 3;
    }
  }

  contextData(name: string) {
    let displayContent = `<div  class="mailertableview" style="margin:0;width: 400px;background: #FFFFFF;">
    <table width="100%" cellspacing="0" cellpadding="0" style="border: 0; padding:40px;">
        <tbody>
        <tr> 
                                <td style="font-family: Roboto;width: 100%;padding-bottom: 40px;">
                                     <img src="assets/icons/brand-logo.svg" alt="Brand Logo">
                                     
                                </td>
          </tr>
        <tr> 
        <td style="font-family: Roboto;width: 100%;color: #27364B;">
                <span style="display: block;font-weight: bold;font-size: 24px;line-height: 28px;">Booking shared</span><br>
                <span style="display: block;font-weight: normal;font-size: 16px;line-height: 19px;padding-top: 8px;">
                ${name} has shared a booking with you
                </span> 
                
                <span style="opacity: 0.1;border-bottom: 1px solid #000000;margin: 0;width: 100%;display: block;padding: 24px 0 0 0;"></span> 
        </td> 
    </tr> 
            <tr>
                <td style="font-family: Roboto;">
                    <table width="100%" cellspacing="0" cellpadding="0" border="0">
                        <tbody>  `;
    this.shareCompData.forEach(e => {
      displayContent += `
                              <tr> 
                                  <td style="font-family: Roboto;width: 100%;color: #27364B;">
                                       <span style="display: block;font-weight: bold;font-size: 16px;line-height: 19px;padding-top: 32px;">
                                          Booking ID ${e.id}
                                      </span> <br>
                                      <span style="display: block;font-weight: normal;font-size: 24px;line-height: 28px;padding-top: 4px;">
                                         ${e.vessel}
                                      </span>
                                  </td>
                              </tr>  
                              <tr> 
                                  <td style="font-family: Roboto;width: 100%;color: #27364B;">
                                       <span style="display: block;font-weight: normal;font-size: 12px;line-height: 14px;padding-top: 24px;opacity: 0.5;">
                                          Booking status
                                      </span> <br>
                                      <span style="display: block;font-weight: normal;font-size: 16px;line-height: 19px;padding-top: 4px;">
                                      ${e.bookingStatus}
                                      </span>
                                  </td>
                              </tr> 
                              <tr> 
                                  <td style="font-family: Roboto;width: 100%;color: #27364B;">
                                       <span style="display: block;font-weight: normal;font-size: 12px;line-height: 14px;padding-top: 24px;opacity: 0.5;">
                                          ETA status
                                      </span> <br>
                                      <span style="display: block;font-weight: normal;font-size: 16px;line-height: 19px;padding-top: 4px;">
                                          ${e.eta}
                                      </span>
                                  </td>
                              </tr>
  
                              <tr> 
                                  <td style="font-family: Roboto;width: 100%;padding: 32px 0;"> 
                                      <button style="padding: 8px 20px;width: 280px; height: 48px;background: #008ECF;border-radius: 6px;font-weight: 500;
                                      font-size: 16px;line-height: 21px;color: #FFF;font-family: Roboto;border: none;pointer-events: none;">
                                      Track this booking</button> 
                                   </td> 
                              </tr> 
  
                            `;
    });
    displayContent += `<tr> 
    <td style="font-family: Roboto;width: 100%;font-family: Roboto;font-style: normal;font-weight: normal;font-size: 12px; line-height: 14px; color: #27364B; opacity: 0.5; padding-bottom: 5px;">
    *for Stolt-Tankers terms of service please refer to :<br> ${environment.POST_LOGOUT_REDIRECT_URI}/terms-of-use/
    </td>
</tr>
<tr>
<td style="font-family: Roboto;width: 100%;font-family: Roboto;font-style: normal;font-weight: normal;font-size: 12px; line-height: 14px; color: #27364B; opacity: 0.5;padding-bottom: 5px;">
*for Stolt-Tankers privacy policy please refer to :<br> ${environment.POST_LOGOUT_REDIRECT_URI}/privacy-and-cookies/
    </td>
</tr>

</tbody> 
</table>
</td>
</tr>      
</tbody> 
</table>
</div>`;
    this.shareEmailTemp.controls['context'].setValue(`${displayContent}`);
  }


  FetchDistData(event: any, type: any) {
    this.isDisabledForm = false;
    let PicHash = event.target.value;
    this.checkHash = PicHash.includes('#');
    let comaSeprate = PicHash.includes(',#');
    let semiColSerate = PicHash.includes(';#');
    switch (type) {
      case EmailSharing.toObj:
        if (PicHash == '#' || comaSeprate || semiColSerate) {
          this.listEnable = true
          this.Distributiondata = this.DistSourceList;
        }
        else {
          this.listEnable = false
          this.Distributiondata = [];
        }
        break;
      case EmailSharing.ccObj:
        if (PicHash == '#' || comaSeprate || semiColSerate) {
          this.listEnableCC = true;
          this.DistributiondataCC = this.DistSourceList
        }
        else {
          this.listEnableCC = false;
          this.DistributiondataCC = [];
        }
        break
      default:
        if (PicHash == '#' || comaSeprate || semiColSerate) {
          this.listEnableBcc = true;
          this.DistributiondataBcc = this.DistSourceList
        }
        else {
          this.listEnableBcc = false;
          this.DistributiondataBcc = [];
        }
        break;
    }

  }

  getListEmails(event: any, type: any) {
    switch (type) {
      case EmailSharing.toObj:
        this.listEnable = false;
        let respemail: any = [];
        event.forEach((err: any) => {
          respemail.push(err.email)
        })
        this.strEmails = this.strEmails.concat(respemail.toString()).replace('#', '')
        break;
      case EmailSharing.ccObj:
        this.listEnableCC = false;
        let respemailcc: any = [];
        event.forEach((err: any) => {
          respemailcc.push(err.email)
        })
        this.strEmailsCC = this.strEmailsCC.concat(respemailcc.toString()).replace('#', '')
        break
      default:
        this.listEnableBcc = false;
        let respemailBcc: any = [];
        event.forEach((err: any) => {
          respemailBcc.push(err.email)
        })
        this.strEmailsBcc = this.strEmailsBcc.concat(respemailBcc.toString()).replace('#', '')
        break;
    }


  }

  getSelfEmail(event:any){
    if((event.target as HTMLInputElement).checked){
      this.shareEmailTemp.get("selfemail")?.patchValue(this.userEmail.toLowerCase())
    }else{
      this.shareEmailTemp.get("selfemail")?.patchValue('')
    }
  }

  showHideNotification(event:any){
    console.log((event.target as HTMLInputElement).checked)
    this.showHideNotifiction = (event.target as HTMLInputElement).checked;
    this.hideShowNoticationStatus.emit(this.showHideNotifiction)
  }

}
